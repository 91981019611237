import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { BCMCharacter } from '../../../modules/bcm/common/components/bcm-character';
import { Table } from 'react-bootstrap';

const BCMGuidesBannerORder: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page play-on-pc-page bcm'} game="bcm">
      <ul className="breadcrumb">
        <li>
          <Link to="/black-clover/">Black Clover M</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/black-clover/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Banner order</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/blackclover/categories/cat_banner.png"
            alt="Reroll"
          />
        </div>
        <div className="page-details">
          <h1>Banner order</h1>
          <h2>
            This guide contains the release order of characters in Black Clover
            Mobile.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Banner" />
        <p>
          The table below shows the release order of characters in the KR/JPN
          versions of the game.
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Character</th>
              <th>Release Date</th>
              <th>Worth pulling?</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter
                    mode="icon"
                    slug="asta-clover-academy"
                    enablePopover
                  />
                  <BCMCharacter
                    mode="icon"
                    slug="mimosa-vermillion-clover-academy"
                    enablePopover
                  />
                  <BCMCharacter
                    mode="icon"
                    slug="yami-sukehiro-clover-academy"
                    enablePopover
                  />
                </div>
              </td>
              <td>26.05.2023</td>
              <td>
                <strong className="green">Yes!</strong> All three Season 1
                characters were great on release and still are at least decent
                in the current KR/JPN meta.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter
                    mode="icon"
                    slug="yami-sukehiro"
                    enablePopover
                  />
                </div>
              </td>
              <td>26.05.2023</td>
              <td>
                <strong className="green">Yes!</strong> The best Red damage
                dealer available on release who still is in top 3 in the current
                meta.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter
                    mode="icon"
                    slug="fuegoleon-vermillion"
                    enablePopover
                  />
                </div>
              </td>
              <td>26.05.2023</td>
              <td>
                <strong className="red">No!</strong> Sadly, Fuego was weak on
                release and despite getting buffed twice nothing had changed and
                he's still the worst Green SSR DPS. meta.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter mode="icon" slug="licht" enablePopover />
                </div>
              </td>
              <td>08.06.2023</td>
              <td>
                <strong className="gold">Maybe</strong>. The first really strong
                Green damage dealer who instantly changed the meta in PVP, but
                it didn't last long - the Green PVP meta ended as abruptly as it
                had began. So only pull him if you care about PVP.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter mode="icon" slug="fana" enablePopover />
                </div>
              </td>
              <td>08.06.2023</td>
              <td>
                <strong className="green">Yes!</strong> Even till today, Fana is
                considered as the best Blue damage dealer when it comes to PVE -
                while you can use her in PVP, there are a few counters to her
                that make her harder to use at higher ranks (but in the lower
                ranks one she can one shot enemy team with her combo).
              </td>
            </tr>
            <tr>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter
                    mode="icon"
                    slug="charlotte-roselei"
                    enablePopover
                  />
                </div>
              </td>
              <td>14.06.2023</td>
              <td>
                <strong className="gold">Maybe</strong>. This is a step up
                banner for an already released characters - so on top of getting
                a copy, you can get additional goodies. She's a great PVE
                character that makes farming Green stages a breeze, and the only
                thing stopping her from dominating PVP are debuff blockers (she
                can apply a debuff that allows a character to get additional
                turn if it kills an enemy with it).
              </td>
            </tr>
            <tr>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter mode="icon" slug="rhya" enablePopover />
                </div>
              </td>
              <td>22.06.2023</td>
              <td>
                <strong className="red">No!</strong> Rhya was released in a
                bugged state that made him (and Licht) do way too much damage.
                They formed the one-shot team in PVP, but after the bug was
                fixed, Rhya's usage in PVP dropped.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter mode="icon" slug="vetto" enablePopover />
                </div>
              </td>
              <td>22.06.2023</td>
              <td>
                <strong className="red">No!</strong> Vetto is a Red Tank who
                believes that offense is the best defense. Sadly, this doesn't
                really work that well in the game and while he can be used in
                PVE in the farming stage, there are lower rarity alternatives
                that aren't much worse than him.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter
                    mode="icon"
                    slug="noelle-silva-swimsuit"
                    enablePopover
                  />
                  <BCMCharacter
                    mode="icon"
                    slug="yuno-swimsuit"
                    enablePopover
                  />
                  <BCMCharacter
                    mode="icon"
                    slug="gauche-adlai-swimsuit"
                    enablePopover
                  />
                </div>
              </td>
              <td>29.06.2023</td>
              <td>
                <strong className="green">Yes, yes, yes!</strong> Summer Noelle
                is someone who you don't want to skip. She's the best character
                in the game - both in PVE and PVP - and it's even a competition.
                The other two seasonal characters are also decent - Gauche pairs
                really well with S. Noelle in PVP and Yuno is a Debuffer/DPS
                hybrid who is better in PVE.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter
                    mode="icon"
                    slug="william-vangeance"
                    enablePopover
                  />
                </div>
              </td>
              <td>04.07.2023</td>
              <td>
                <strong className="green">Yes!</strong> This is a step up banner
                for an already released characters - so on top of getting a
                copy, you can get additional goodies. If you're missing him
                (like I did in KR/JPN), this banner will be your saving grace.
                William even till today is considered as one of the best
                supports in the game.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter mode="icon" slug="kiato" enablePopover />
                </div>
              </td>
              <td>13.07.2023</td>
              <td>
                <strong className="green">Yes!</strong> Kiato is a PVP monster
                who can one-shot most characters in PVP - even if their element
                counters his. His multipliers are massive and his Special Skill
                always Crits when he is buffed, so you can build him full Crit
                DMG and abuse it.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter mode="icon" slug="kahono" enablePopover />
                </div>
              </td>
              <td>13.07.2023</td>
              <td>
                <strong className="red">No!</strong> Kahono is a decent
                Healer/Buffer hybrid, but she's more on the defensive side which
                makes her a bit harder to use in PVP (as there offensive buffs
                are worth more) - also Shielders have more value there than pure
                healers. For PVE she's a decent choice, but Season 3 Charmy is
                just much stronger than her at this role.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter
                    mode="icon"
                    slug="rades-spirito"
                    enablePopover
                  />
                </div>
              </td>
              <td>17.07.2023</td>
              <td>
                <strong className="green">Yes!</strong> This is a step up banner
                for an already released characters - so on top of getting a
                copy, you can get additional goodies. A beast of a Shielder who
                from day 1 is a meta pick in PVP and PVE - only Summer Noelle
                can apply stronger Shields than him, but you can use them
                together to piss off all attackers in the Arena.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter
                    mode="icon"
                    slug="asta-swimsuit"
                    enablePopover
                  />
                  <BCMCharacter
                    mode="icon"
                    slug="charmy-pappitson-swimsuit"
                    enablePopover
                  />
                  <BCMCharacter
                    mode="icon"
                    slug="vanessa-enoteca-swimsuit"
                    enablePopover
                  />
                </div>
              </td>
              <td>31.07.2023</td>
              <td>
                <strong className="gold">Maybe</strong>. The first Season where
                there are some underwhelming characters - which was probably
                caused after the drama revolving around Summer Noelle being so
                strong. The best among the three is Summer Charmy - she's a
                great Healer/Buffer who is a nightmare in PVP when invested into
                and also she shines in any PVE content. Sadly, Vanessa is just
                terrible and Asta is actually overshadowed by both his other
                variants. It's safer to skip the banner and just pick Charmy
                from the Divine Gate quest.
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default BCMGuidesBannerORder;

export const Head: React.FC = () => (
  <Seo
    title="Banner order | Black Clover M | Prydwen Institute"
    description="This guide contains the release order of characters in Black Clover Mobile."
  />
);
